// CREDITS: https://medium.com/@karolis.augustauskas/how-to-disable-scrolling-event-in-react-number-inputs-using-hooks-a07b335923ab#:~:text=Simply%20import%20the%20useDisableNumberInputScroll%20hook,now%20have%20scrolling%20events%20disabled.

import { useEffect } from 'react';

export const useDisableNumberInputScroll = () => {
  useEffect(() => {
    // Define a function to prevent the default scroll behavior
    const handleWheel = (e) => {
      e.preventDefault();
    };
    
    // Prevent entering "e", "+", or "-" in number inputs
    const handleKeyDown = (e) => {
        if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
          e.preventDefault();
        }
      };

    // Find all number input elements in the document
    const numberInputs = document.querySelectorAll('input[type="number"]');

    // Attach the handleWheel function as an event listener to each number input
    numberInputs.forEach((input) => {
      input.addEventListener('wheel', handleWheel, { passive: false });
      input.addEventListener('keydown', handleKeyDown);
    });


    // Clean up by removing the event listeners when the component unmounts
    return () => {
      numberInputs.forEach((input) => {
        input.removeEventListener('wheel', handleWheel);
        input.removeEventListener('keydown', handleKeyDown);
      });
    };
  }, []); // The empty dependency array ensures that this effect runs once, like componentDidMount
};

export default useDisableNumberInputScroll;