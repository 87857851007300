import { useContext } from 'react';
import { UserInfoContext } from '../context/UserInfoContext';

export default function useUserInfoContext() {
    const context = useContext(UserInfoContext);

    if (!context) {
        throw Error('useUserInfoContext must be used inside a UserInfoContextProvider');
    }

    return context;
}