import React from 'react';
import '../../styles/sign-in-up.css';
import { Link } from "react-router-dom";
import useLogin from '../../hooks/useLogin';
import BackArrow from '../BackArrow';
import validators from '../../helpers/validators';

export default function SignInPage() {
    const { login, error, setError } = useLogin();
    const { isValidPassword, isValidUsername } = validators;

    const [formData, setFormData] = React.useState({
        login_credential: "",
        password: ""
    })

    // Handle the form change
    function handleChange(event) {
        setError(null);
        setFormData((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        })
    }

    // Handle the submission of the form
    async function handleSignIn() {
        if (isValidPassword(formData.password, "", {}, false) && isValidUsername(formData.login_credential, {})) {
            await login(formData);
        } else setError("Incorrect email/username or password.");
    }

    return (
        <div id="sign-up--container">
            <BackArrow 
                to="/"
                additionalClass="absolute"
            />
            <div className='sign-in-up--background'>
                <div className="contact-section">
                    <div className="contact-header">
                        <h2>Sign In</h2>
                    </div>
                    {/* <div className="contact-info">
                        <p>Please fill in the form below to sign up as a student or tutor.</p>
                    </div> */}
                    <div className="form-container">
                        <div className="form-row">
                            <label htmlFor="login_credential" className="label">Username or Email:</label>
                            <input 
                                type="login_credential" 
                                id="login_credential" 
                                name="login_credential" 
                                placeholder="Enter your username or email" 
                                className={`sign-up--elements${error ? " error-borders" : ""}`} 
                                onChange={handleChange} 
                            />
                        </div>
                        <div className="form-row" style={{ marginBottom: "7px" }}>
                            <label htmlFor="password" className="label">Password:</label>
                            <input 
                                type="password" 
                                id="password" 
                                name="password" 
                                placeholder="Enter your password" 
                                className={`sign-up--elements${error ? " error-borders" : ""}`}  
                                onChange={handleChange} 
                            />
                        </div>

                        <div className="forgot-password--button">
                            <Link to="/forgot-password">Forgot password?</Link>
                        </div>
                        
                        {error && <p className="error-p">{error}</p>}
                        <div className="form-row">
                            <button type="submit" className="submit-button" onClick={handleSignIn}>Sign In</button>
                        </div>
                        <div id="sign-in-up-switch">
                            <p>Don't have an account?</p>
                            <Link to="/sign-up">Sign up.</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
