import { useState, useEffect } from "react";
import api from "../../api.js";
import useLoaderContext from "../../hooks/useLoaderContext.js";
import useMajorsContext from "../../hooks/useMajorsContext.js";
import useErrorContext from "../../hooks/useErrorContext.js";

export default function CoursesSearchBar({ 
    major, majorId, setCourses, setMajor, setMajorId, searchCourse, setSearchCourse, page, topRef, setTotalPages, totalPages
}) {
    const [majorsOptions, setMajorsOptions] = useState([]);

    const { dispatch:loadingDispatch } = useLoaderContext();
    const { majors, dispatch:majorsDispatch } = useMajorsContext();
    const { dispatch:errorDispatch } = useErrorContext();

    const [hasChangedPage, setHasChangedPage] = useState(false);
    
    useEffect(() => {
        // Get the majors from the database
        const fetchMajors = async () => {
            try {
                loadingDispatch({ type: 'LOAD' });
                const res = await api.get('/getMajors/');
                const { data } = res;

                majorsDispatch({ type: 'SET_MAJORS', payload: data });
                loadingDispatch({ type: 'NO_LOAD' });
            } catch (error) {
                errorDispatch({ type: 'ERROR' });
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        if (!majors) fetchMajors();
    }, [majorsDispatch, majors, loadingDispatch, errorDispatch]);

    useEffect(() => {
        if (majors) {
            setMajorsOptions(() => {
                return majors.map((major, id) => <option value={`${major.id}-${major.name}`} key={id}>{major.name}</option>)
            })
        }
    }, [majors]);

    // Handle the searching of the course
    function handleSearch(event) {
        setSearchCourse(event.target.value);
    }

    useEffect(() => {
        // Scroll to top if the page changes
        function scrollToTop() {
            // this is to know when we should start scrolling
            if (!hasChangedPage && page !== 1) {
                setHasChangedPage(true);
            }

            // scroll to top
            if (hasChangedPage) {
                topRef.current.scrollIntoView({
                    behavior: 'smooth', 
                    block: 'start'
                });
            }
        }

        // Search for courses
        const searchCourses = async() => {
            try {
                const res = await api.get("/getCourses/?filtered=true", {
                    params: {
                        page,
                        filtered: true, 
                        major_id: majorId || undefined,
                        search_entry: searchCourse || undefined
                    }
                })
                
                const { data } = res;
                setCourses(data.courses);
                scrollToTop(); // scroll to top when the courses are fetched
                
                // always keep track of the total pages
                if (!totalPages || totalPages !== data.pagination) setTotalPages(data.pagination.totalPages);
            } catch (error) {
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });            
            }
        }

        searchCourses();
    }, [searchCourse, majorId, major, setCourses, totalPages, page, topRef, setTotalPages, errorDispatch, hasChangedPage])

    // Handle the change of the major
    function handleChange(event) {
        const currVal = event.target.value;

        if (currVal === "") {
            setMajorId("");
            setMajor("");
        } else {
            setMajor(currVal.substring(currVal.search('-') + 1));
            setMajorId(currVal.substring(0,currVal.search('-')));
        }
    }

    return (
        <div className="search--container">
            <div id="search-bar">
                <input type="text" placeholder="Search Courses" onChange={handleSearch} value={searchCourse} autoComplete={"true"} />
                {searchCourse !== "" ? 
                    <span 
                        className="material-symbols-outlined icon-search-bar" 
                        id="close-icon"
                        onClick={() => setSearchCourse("")}
                    >close</span>
                    :
                    <span className="material-symbols-outlined icon-search-bar">search</span>
                }
            </div>

            <div className="courses-select">
                <div className="select--container courses-select--container">
                    <select
                        value={`${majorId}-${major}`}
                        onChange={handleChange}
                        className="select-element"
                    >
                        <option value="">All Majors</option>
                        {majorsOptions}
                    </select>
                </div>
            </div>
        </div>
    )
}