import { useState } from "react"
import BackArrow from "../BackArrow"
// import validators from "../../helpers/validators";
import { useNavigate } from "react-router-dom";
import api from "../../api.js";
import useLoaderContext from "../../hooks/useLoaderContext.js";
import useErrorContext from "../../hooks/useErrorContext.js";

export default function ForgotPassword() {
    // const { isValidEmail } = validators;
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [email, setEmail] = useState("");
    
    const forgotPassword = async () => {
        try {
            await api.post("/user/forgotPassword", {
                email
            });
            navigate('/verification-code', { state: { email } });
        } catch (error) {   
            loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
        }
    }

    // Submit the email and call the API
    async function handleSubmit() {
        // const err = {};
        // if (isValidEmail(email, err)) {
            await forgotPassword(email);
        // } else {
        //     setError(err);
        // }
    }

    // Handle form change
    function handleChange(event) {
        setError(null);
        setEmail(event.target.value)
    }

    return (
        <div id="sign-up--container">
            <BackArrow 
                to="/sign-in"
                additionalClass="absolute"
            />
            <div className='sign-in-up--background' style={{ animation: "none" }}>
                <div className="contact-section" style={{ animation: "none", opacity: "1" }}>
                    <div className="contact-header">
                        <h2>Forgot Password?</h2>
                    </div>

                    <h6 className="forgot-password-p">Enter your email and we will send back a link to reset your password.</h6>
                    <div className="form-container">
                        <div className="form-row">
                            <label htmlFor="username" className="label">Email:</label>
                            <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                placeholder="Enter your email" 
                                className={`sign-up--elements${error ? " error-borders" : ""}`} 
                                value={email}
                                onChange={handleChange}
                            />
                        </div>
                        {error && <p className="error-p" style={{ marginTop: "-10px" }}>Incorrect email</p>}

                        <div className="form-row">
                            <button type="submit" className="submit-button" onClick={handleSubmit}>Reset Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}