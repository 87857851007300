import React, { createContext, useReducer } from "react"

export const LanguagesContext = createContext();

const languagesReducer = (state, action) => {
    switch(action.type) {
        case 'SET_LANGUAGES':
            return action.payload;

        default: 
            return state;
    }
} 

export const LanguagesContextProvider = ({ children }) => {
    const [languages, dispatch] = useReducer(languagesReducer, null);

    return (
        <LanguagesContext.Provider value={{ languages, dispatch }}>
            {children}
        </LanguagesContext.Provider>
    )
}