import { SubscriptionContext } from "../context/SubscriptionContext";
import { useContext } from "react";

export default function useSubscriptionContext() {
    const context = useContext(SubscriptionContext);

    if (!context) {
        throw Error('useSubscriptionContext must be used inside a SubscriptionContextProvider');
    }

    return context;
}