import { useEffect, useState } from "react";
import useBookingsContext from "../hooks/useBookingsContext";
import usePendingsContext from "../hooks/usePendingsContext";
import useUserInfoContext from "../hooks/useUserInfoContext";
import useLoaderContext from "../hooks/useLoaderContext";
import useErrorContext from "../hooks/useErrorContext";
import useAuthContext from "../hooks/useAuthContext";
import bookingCalls from "./bookingCalls";
import pendingCalls from "./pendingCalls";
import userCalls from "./userCalls";
import useSubscriptionContext from "../hooks/useSubscriptionContext";

export default function ApiCalls() {
    const { dispatch:bookingsDispatch } = useBookingsContext();
    const { dispatch:pendingsDispatch } = usePendingsContext();
    const { isTutor } = useUserInfoContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { dispatch:userInfoDispatch } = useUserInfoContext();
    const { isAccountActivated, dispatch:subscriptionDispatch } = useSubscriptionContext();
    const { token } = useAuthContext();

    const { getUserInfo, getActivationStatus, getRemainingDays } = userCalls;
    const { getUserBookings, getTutorBookings } = bookingCalls;
    const { getUserPendings, getTutorPendings } = pendingCalls;

    const [isFetched, setIsFetched] = useState({
        remainingDays: false,
        userBookings: false,
        tutorBookings: false,
        userPendings: false,
        tutorPendings: false
    });

    // Check when everything is fetched
    useEffect(() => {
        if (isFetched.userBookings && isFetched.userPendings) {
            if ((isTutor && isFetched.tutorBookings && isFetched.tutorPendings && isFetched.remainingDays) || !isTutor) {
                loadingDispatch({ type: 'NO_LOAD' });
            }
        }
    }, [isFetched, loadingDispatch, isTutor]);


    // Get the user info
    useEffect(() => {
        if (token) getUserInfo(userInfoDispatch, loadingDispatch, errorDispatch);
    }, [token, errorDispatch, loadingDispatch, userInfoDispatch, getUserInfo]);


    // Get the activation status if tutor
    useEffect(() => {
        if (isTutor !== null && isTutor) getActivationStatus(subscriptionDispatch, loadingDispatch, errorDispatch);
    }, [isTutor, getActivationStatus, loadingDispatch, subscriptionDispatch, errorDispatch]);


    // Get the remaining days of the tutor is subscribed
    useEffect(() => {
        if (isAccountActivated) getRemainingDays(subscriptionDispatch, errorDispatch, loadingDispatch, setIsFetched);
    }, [subscriptionDispatch, loadingDispatch, errorDispatch, getRemainingDays, isAccountActivated])


    // Get the bookings and pendings for both users and tutors
    useEffect(() => {
        if (isTutor !== null) {
            getUserBookings(bookingsDispatch, loadingDispatch, errorDispatch, setIsFetched);
            getUserPendings(pendingsDispatch, loadingDispatch, errorDispatch, setIsFetched);

            if (isTutor) {
                getTutorBookings(bookingsDispatch, loadingDispatch, errorDispatch, setIsFetched);
                getTutorPendings(pendingsDispatch, loadingDispatch, errorDispatch, setIsFetched);
            }
        }
    }, [isTutor, bookingsDispatch, loadingDispatch, errorDispatch, pendingsDispatch, getTutorBookings, getUserBookings, getTutorPendings, getUserPendings])
}