import { OverflowContext } from "../context/OverflowContext";
import { useContext } from "react";

export default function useOverflowContext() {
    const context = useContext(OverflowContext);

    if (!context) {
        throw Error('useOverflowContext must be used inside a OverflowContextProvider');
    }

    return context;
}