import { useContext } from 'react';
import { ErrorContext } from '../context/ErrorContext';

export default function useErrorContext() {
    const context = useContext(ErrorContext);

    if (!context) {
        throw Error('useBookingContext must be used inside a ErrorContextProvider');
    }

    return context;
}