import React from "react";
import "../styles/styles.css";
import { Navbar } from "./Navbar";

export default function PageIntro({ active, background, title, description }) {
    return (
        <div id="page-intro--container">
            <Navbar active={active} />
            <div id="page-intro" className={background}>
                <h1>{title}</h1>
                <h6>{description}</h6>
            </div>
        </div>
    )
}