import { useLocation } from "react-router-dom"
import { useState, useEffect } from "react";
import apiPrivate from "../../apiPrivate.js";

export default function MailConfirmation() {
    const location = useLocation();
    const email = location.state ? location.state.email : "";
    
    const [canResend, setCanResend] = useState(false);
    const resendInterval = 60000; // 1 min
    const resendIntervalSeconds = resendInterval / 1000;
    const [timer, setTimer] = useState(resendIntervalSeconds);

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    setCanResend(true);
                    return resendIntervalSeconds; // Reset timer
                }

                return prevTimer - 1;
            });
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(countdown);
    }, [timer, resendIntervalSeconds]);

    // API to resent the email
    const resendEmail = async () => {
        try {
            await apiPrivate.post("/user/sendConfirmationEmail", {});
        } catch (error) {
            console.log(error.response?.data?.detail || "An error occurred");
        }
    };

    // Handle the resend of the email
    async function handleResend() {
        await resendEmail();
        setCanResend(false);
        setTimer(resendIntervalSeconds);
    }

    return (
        <div className="email-confirmation--background">
            <div className="email-confirmation--container">
                <lord-icon
                    src="https://cdn.lordicon.com/xtnsvhie.json"
                    trigger="hover"
                    colors="primary:#00ac97"
                    style={{ width: "100px", height: "100px", marginBottom: "10px" }}
                ></lord-icon>

                <h1>Email Confirmation</h1>
                <p>
                    <span>An email has been sent to </span>
                    <span className="light-green">{email}</span>
                    <span> confirm the validity of the email address. Please check your inbox and follow the instructions to complete your registration.</span>
                </p>

                <p className="resend-email--container">
                    <span>Can't find it? </span>
                    {canResend ? 
                        <button 
                            onClick={handleResend}
                            className="resend-otp--button"
                        >Resend</button>
                        :
                        <p>
                            <span>Resend in </span>
                            <span className="resend-otp--button">{timer}</span>
                            <span> seconds</span>
                        </p>
                    }
                </p>
            </div>
        </div>
    )
}