import { useState } from 'react';
import BookingNotification from '../Notifications/BookingNotification';
import useOverflowContext from '../../hooks/useOverflowContext';
import mappings from '../../data/daysMappings';
import Notification from '../Notifications/Notification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastHelpers from '../../helpers/toastHelper';
import apiPrivate from '../../apiPrivate.js';

export default function BookingInfoBox(
    { name, email, courseDetails, date, bookingId, 
        isTutor, phoneNumber = "", setBookings, isPending, 
        isTutorBookings, userId, setDate, hourlyRate, setMessage, profilePictureURL }
) {
    const { days, months } = mappings;
    const { toastError, toastSuccess } = toastHelpers;
    
    const [bookingNotification, setBookingNotification] = useState({ show: false });
    const [banNotification, setBanNotification] = useState(false);
    const [isCancel, setIsCancel] = useState(false);
    const newDate = new Date(date);

    const [hasPhoneNumber] = useState(phoneNumber && phoneNumber !== "");
   
    const { dispatch:overflowDispatch } = useOverflowContext();

    // Handle the canceling of the session
    function handleCancel() {
        setBookingNotification({ show: true });
        setIsCancel(true);
        overflowDispatch({ type: 'SET_FREEZE', payload: true });
    }

    // Handle the approval of the session
    function handleAccept() {
        setBookingNotification({ show: true });
        overflowDispatch({ type: 'SET_FREEZE', payload: true });
    }

    // Handle the blocking of a user
    function handleBan() {
        setBanNotification(true);
    }

    function handleCancelBan() {
        setBanNotification(false);
    }

    const handleConfirmBan = async () => {
        try {
            await apiPrivate.post("/tutor/banUser", 
                {
                    user_id: userId,  
                }
            );
            setBanNotification(false);
            toastSuccess("User successfully banned.");
        } catch (error) {
            toastError("There was an error banning the user. Please try again.");
        }
    }

    return (
        <div className="booking-info-box--container booking-info-box--container-height-pending">
            <ToastContainer
                position="bottom-left"
                hideProgressBar={false}
                draggable
                theme="colored"
            />

            {bookingNotification.show && 
            <BookingNotification 
                setBookingNotification={setBookingNotification}
                course={courseDetails.name}
                day={date}
                hour={new Date(date).getHours()}
                isBooking={false}
                student={name}
                isTutor={isTutor}
                bookingId={bookingId}
                setBookings={setBookings}
                isPending={isPending}
                isTutorBookings={isTutorBookings}
                courseId={courseDetails.id}
                userId={userId}
                setDate={setDate}
                setMessage={setMessage}
                isCancel={isCancel}
            />}

            {banNotification &&
                <Notification 
                    handleConfirm={handleConfirmBan}
                    handleCancel={handleCancelBan}
                    title={"Block Request"}
                    content={`Are you sure you want to block ${name}?`}
                    doneButton={false}
                    isWarning={true}
                />
            }

            <div className="profile--container" style={!profilePictureURL ? { marginLeft: "0" } : { marginTop: "0" }}>
                {profilePictureURL ?
                    <img 
                        src={profilePictureURL}
                        alt="Tutor Profile"
                        className="profile-picture profile-img--bookings"
                        loading="lazy"
                    />
                    :
                    <span className="material-symbols-outlined small-profile" id="tutor-profile">account_circle</span>
                }   
                
                {isTutorBookings && isPending && <button onClick={handleBan} className="block-button">Block</button>}
            </div>

            <div className="booking-info-box--info">
                <div className="booking-info-box--title">
                    <div className="pending-ban--container">
                        <h3>{name}</h3>
                    </div>

                    <div className="pending-buttons">
                        {isTutorBookings && isPending &&
                        <lord-icon
                            src="https://cdn.lordicon.com/lomfljuq.json"
                            trigger="hover"
                            colors="primary:#00ac97"
                            style={{ width: "35px", height: "35px", cursor: "pointer" }}
                            onClick={handleAccept}
                        ></lord-icon>
                        }  

                        {isTutorBookings && 
                        <lord-icon
                            src="https://cdn.lordicon.com/zxvuvcnc.json"
                            trigger="hover"
                            state="hover-cross-3"
                            colors="primary:#dc3545"
                            style={{ width: "35px", height: "35px", cursor: "pointer", marginLeft: "5px" }}
                            onClick={handleCancel}
                        ></lord-icon>}

                        {!isTutorBookings &&
                        <lord-icon
                            src="https://cdn.lordicon.com/zxvuvcnc.json"
                            trigger="hover"
                            state="hover-cross-3"
                            colors="primary:#dc3545"
                            style={{ width: "35px", height: "35px", cursor: "pointer" }}
                            onClick={handleCancel}
                        ></lord-icon>}
                    </div>
                </div>

                <div className="booking-info-box--contact">
                    <div className={`booking-info-box--contact-section${hasPhoneNumber ? " right-border" : ""}`}>
                        <span className="material-symbols-outlined">
                            alternate_email
                        </span>
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>

                    {hasPhoneNumber && 
                    <div className="booking-info-box--contact-section left-padding">
                        <span className="material-symbols-outlined">
                           call
                        </span>
                        <a href={`tel:+961${phoneNumber}`}>{`${phoneNumber.substring(0,2)} ${phoneNumber.substring(2)}`}</a>
                    </div>}
                </div>
                
                <div className="booking-info-box--section">
                    <p>
                        <span className="booking-info-box--section-title">Course:</span>
                        <span>{courseDetails.name}</span>
                    </p>
                </div>

                <div className="booking-info-box--section">
                    <p>
                        <span className="booking-info-box--section-title">Date:</span>
                        <span>{`${days[newDate.getDay()]} ${newDate.getDate()} ${months[newDate.getMonth()]}, ${newDate.getFullYear()}`}</span>
                    </p>
                </div>

                <div className="booking-info-box--section">
                    <p>
                        <span className="booking-info-box--section-title">Time:</span>
                        <span>{`${newDate.getHours()}:00`}</span>
                    </p>
                </div>

                {hourlyRate && <div className="booking-info-box--section">
                    <p>
                        <span className="booking-info-box--section-title">Total:</span>
                        <span>{`$ ${hourlyRate}`}</span>
                    </p>
                </div>}
            </div>
        </div>
    )
}