import React, { createContext, useReducer } from "react"

export const MajorsContext = createContext();

const majorsReducer = (state, action) => {
    switch(action.type) {
        case 'SET_MAJORS':
            return action.payload;

        default: 
            return state;
    }
} 

export const MajorsContextProvider = ({ children }) => {
    const [majors, dispatch] = useReducer(majorsReducer, null);

    return (
        <MajorsContext.Provider value={{ majors, dispatch }}>
            {children}
        </MajorsContext.Provider>
    )
}