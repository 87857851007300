import apiPrivate from "../apiPrivate";

// Get the user's booking
const getUserBookings = async (bookingsDispatch, loadingDispatch, errorDispatch, setIsFetched) => {
    try {
        loadingDispatch({ type: 'LOAD' });
        const { data } = await apiPrivate.get("/user/getBookings");
        
        bookingsDispatch({ type: 'INIT_BOOKINGS', payload: {
            isUser: true,
            newBookings: data.sort((a, b) => a.month - b.month)
        }});
        setIsFetched(prev => {
            return {
                ...prev,
                userBookings: true
            }
        })
    } catch (error) {
        loadingDispatch({ type: 'NO_LOAD' });
        errorDispatch({ type: 'ERROR', isNetworkError: !error.response }); 
    }
}

// Get the tutor's booking
const getTutorBookings = async (bookingsDispatch, loadingDispatch, errorDispatch, setIsFetched) => {
    try {
        loadingDispatch({ type: 'LOAD' });
        const res = await apiPrivate.get("/tutor/getHours?booked_only=True");
        const { data } = res;
        
        bookingsDispatch({ type: 'INIT_BOOKINGS', payload: {
            isUser: false,
            newBookings: data.sort((a, b) => a.month - b.month)
        }});

        setIsFetched(prev => {
            return {
                ...prev,
                tutorBookings: true
            }
        })
    } catch (error) {
        loadingDispatch({ type: 'NO_LOAD' });
        errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
    }
}

const bookingCalls = {
    getTutorBookings, 
    getUserBookings
}

export default bookingCalls;