export default function BannedUserMoreInfo({ name, profilePictureURL, phone_number, email, closeMoreInfo }) {
    return (
        <div className="banned-user-more-info--bg">
            <div className="banned-user-more-info--container">
                {profilePictureURL ?
                    <img 
                        src={profilePictureURL}
                        alt="Tutor Profile"
                        className="profile-picture profile-img--banned-user-more"
                        loading="lazy"
                    />
                    :
                    <span className="material-symbols-outlined profile-ban--more-info">account_circle</span>
                }   
                
                <div className="banned-user-box--contacts">
                    <h3>{name}</h3>

                    <div className={`booking-info-box--contact-section`}>
                        <span className="material-symbols-outlined">
                            alternate_email
                        </span>
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>

                    {phone_number && 
                    <div className="booking-info-box--contact-section">
                        <span className="material-symbols-outlined">
                        call
                        </span>
                        <a href={`tel:+961${phone_number}`}>{`${phone_number.substring(0,2)} ${phone_number.substring(2)}`}</a>
                    </div>}
                    
                    <button className="red-button" onClick={closeMoreInfo}>Close</button>
                </div>

            </div>
        </div>
    )
}