import { toast } from "react-toastify";

const toastError = (message) => {
    toast.error(message, {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "toast-error",
        progressClassName: "toast-error-progress"
    });
}

const toastSuccess = (message) => {
    toast.success(message, {
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
        className: "toast-success",
        progressClassName: "toast-success-progress"
    });
}

const toastHelpers = {
    toastError,
    toastSuccess
}

export default toastHelpers;