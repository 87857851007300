import { useState } from "react";
import useSubscriptionContext from "../../hooks/useSubscriptionContext";
import Notification from "../Notifications/Notification";
import apiPrivate from "../../apiPrivate";
import useLoaderContext from "../../hooks/useLoaderContext";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import useUserInfoContext from "../../hooks/useUserInfoContext";

export default function PackageContainer({ subscriptionTitle, subscriptionPrice, subscriptionDuration, subscriptionDurationText, subscriptionDescription, prevRoute }) {
    const { isTrialUsed } = useSubscriptionContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const { user } = useUserInfoContext();
    const [showNotification, setShowNotification] = useState(false);

    const navigate = useNavigate();

    const invoice = subscriptionDuration === 1 ? "Studytron Monthly Tutor Subscription" : "Studytron Semester Tutor Subscription";

    // Handle upgrade
    const confirmUpgrade = async () => {
        try {
            loadingDispatch({ type: 'LOAD' });
            const { data } = await api.post("/sendPaymentRequest", {
                invoice,
                amount: subscriptionPrice,
                duration: subscriptionDuration,
                tutor_id: user.id
            });            
            
            loadingDispatch({ type: 'NO_LOAD' });
            window.open(data.response.data.collectUrl);
        } catch (error) {
            console.log(error);
        }
    }

    // Handle confirm of free trial
    const confirmFreeTrial = async () => {
        try {
            loadingDispatch({ type: 'LOAD' });
            await apiPrivate.post("/activateFreeTrial");
            loadingDispatch({ type: 'NO_LOAD' });
            navigate(prevRoute);
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            // TOAST ERROR
        }
    }

    // Handle click of the plan
    function handleClick() {
        if (!isTrialUsed) {
            setShowNotification(true);
        } else {
            confirmUpgrade();
        }
    }
    
    return (
        <div className="package--container">
            {showNotification && 
                <Notification 
                    title={"Activate Free Trial?"}
                    content={"Are you sure you want to activate your 14-day free trial? No credit card is needed for this process."}
                    handleConfirm={confirmFreeTrial}
                    handleCancel={() => setShowNotification(false)}
                />
            }
            <h3>{subscriptionTitle}</h3>

            <div className="subscription-price--container">
                <h2>${subscriptionPrice}</h2>
                <p>{subscriptionDurationText}</p>
            </div>

            <p>{subscriptionDescription}</p>

            <div>
                <button onClick={handleClick}>{isTrialUsed ? "Get Plan" : "Start 14-Day Free Trial"}</button>
                {!isTrialUsed && <p className="no-credit-card-text">No credit card required</p>}
            </div>
        </div>
    )
}