import { Link } from "react-router-dom";

export default function SuccessPayment() {
    return (
        <div className="notification--whole-page whole-page--green-bg">
            <div className="notification--container">
                <lord-icon
                    src="https://cdn.lordicon.com/oqdmuxru.json"
                    trigger="hover"
                    colors="primary:#00ac97"
                    style={{ width: "100px", height: "100px" }}
                ></lord-icon>

                <h1>Payment Successful</h1>
                <div className="notification-container--p">
                    Your payment was successfully received. Thank you for using Studytron!
                </div>

                <div className="payment--buttons">
                    <Link className="success-payment--button" to="/">Back to Home</Link>
                </div>
            </div>
        </div>
    )
}