import { memo } from "react";
import dateHelpers from '../../helpers/dateHelpers';
import Calendar from "react-calendar";
import useSubscriptionContext from "../../hooks/useSubscriptionContext";

export const EditHoursCalendar = memo(({ date, setDate, bookedDays }) => {
    const { areDatesEqual } = dateHelpers;
    const { remainingDays } = useSubscriptionContext();
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + remainingDays);    

    // Handle the calendar
    function handleCalendar(date) {
        setDate(date);
    }

    // Color the right calendar color
    function dateClass(date) {
        if (bookedDays) {
            const dateToFind = new Date(date);
            const bookedClass = "react-calendar__tile--booked-green";
            const selectedClass = "react-calendar__tile--selected";
    
            for (let i = 0; i < bookedDays.length; i++) {
                const currDay = new Date(bookedDays[i]);
    
                if (areDatesEqual(today, dateToFind)) return selectedClass;
                else if (areDatesEqual(dateToFind, currDay)) return bookedClass;
            }

            return "";
        }
    }

    return (
        <Calendar 
            onChange={handleCalendar}
            minDate={new Date(today)}
            value={date}
            maxDate={futureDate}
            tileClassName={({ activeStartDate, date }) =>
                dateClass(date)
            }
        />
    )   
})