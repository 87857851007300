import { useState } from 'react';
import useLoaderContext from './useLoaderContext';
import { useNavigate } from 'react-router-dom';
import api from "../api.js";
import useErrorContext from './useErrorContext.js';
import apiPrivate from '../apiPrivate.js';

export default function useSignup() {
    const [error, setError] = useState(null);
    const { dispatch: loadingDispatch } = useLoaderContext();
    const navigate = useNavigate();
    const { dispatch:errorDispatch } = useErrorContext();

    // Upload the profile picture
    const uploadProfilePicture = async (file, token) => {
        try {
            const formDataFile = new FormData();
            formDataFile.append('file', file);

            await apiPrivate.post('/user/uploadProfilePicture', formDataFile);
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
        }
    }
    
    const signup = async (formData, file, setFormData, emptyForm) => {
        const confirm_password = formData.confirm_password;

        try {
            loadingDispatch({ type: 'LOAD' });
            setError(null);

            // Send the FormData using axios
            const response = await api.post('/signup', formData);
            const { token } = response.data;

            // If successful, upload profile picture as well
            if (file) await uploadProfilePicture(file, token);

            // Navigate to email confirmation page on success
            navigate('/mail-confirmation', { state: { token, email: formData.email } });
            loadingDispatch({ type: 'NO_LOAD' });

        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
            setError(error.response?.data || "An error occurred");

            // Restore the confirm_password field if needed
            setFormData(prev => ({
                ...prev,
                confirm_password
            }));
        }
    };

    return { signup, error, setError };
}
