import { useContext } from 'react';
import { CoursesContext } from '../context/CoursesContext';

export default function useCoursesContext() {
    const context = useContext(CoursesContext);

    if (!context) {
        throw Error('useBookingContext must be used inside a CoursesContextProvider');
    }

    return context;
}