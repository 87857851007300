import { useState, useEffect, useRef } from "react";
import "../../styles/styles.css";
import "../../styles/tutors.css";
import PageIntro from "../PageIntro";
import { TutorsSearchBar } from "./TutorsSearchBar";
import TutorBox from "./TutorBox";
import { Footer } from "../Footer";
import Pagination from "../Pagination";
import useAuthContext from "../../hooks/useAuthContext";
import api from "../../api.js";
import { useLocation } from "react-router-dom";
import useLoaderContext from "../../hooks/useLoaderContext.js";
import useErrorContext from "../../hooks/useErrorContext.js";
import apiPrivate from "../../apiPrivate.js";

export default function Tutors() {
    const location = useLocation();
    const course = location.state ? location.state.course : null;
    const courseFormat = course ? `${course.id}-${course.name}` : null;

    const [formData, setFormData] = useState({ "course": courseFormat ? courseFormat : "", "rating": "", "language": "", "rate" : "", "searchEntry" : ""});
    
    const [tutors, setTutors] = useState([]);
    const [tutorsElements, setTutorsElements] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);

    const { token } = useAuthContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();

    const topRef = useRef(null);
    const [showFilters, setShowFilters] = useState(false);
    const [hasChangedPage, setHasChangedPage] = useState(false);

    // Extract the id and the name
    const extractId = (value) => {
        return value.substring(0, value.search('-'));
    }

    const extractName = (value) => {
        return value.substring(value.search('-') + 1);
    }

    useEffect(() => {
        // Scroll back to the top every time a page changes
        function scrollToTop() {
            if (!hasChangedPage && page !== 1) {
                setHasChangedPage(true);
            }

            // scroll to top
            if (hasChangedPage) {
                topRef.current.scrollIntoView({
                    behavior: 'smooth', 
                    block: 'start'
                });
            }
        }

        // Search for tutors
        const fetchTutors = async (page) => {
            try {
                const apiOption = token ? apiPrivate : api;
                
                const params = {
                    page,
                    course_id: formData.course ? extractId(formData.course) : undefined,
                    search_entry: formData.searchEntry || undefined,
                    language_id: formData.language ? extractId(formData.language) : undefined,
                    rate: formData.rate ? Number(formData.rate) : undefined,
                    rating: formData.rating ? Number(formData.rating) : undefined,
                };
                
                const { data } = await apiOption.get('/getTutors/', { 
                    params
                });
                setTutors(data.tutors);

                scrollToTop();
                
                // always keep track of the total pages
                if (!totalPages || totalPages !== data.pagination) setTotalPages(data.pagination.totalPages);
            } catch (error) {
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
            }
          };
        
        fetchTutors(page);
    }, [formData, page, totalPages, token, loadingDispatch, errorDispatch, hasChangedPage]);
    
    // Create the tutor boxes that should be rendered
    useEffect(() => {
        setTutorsElements(tutors.map((tutor, id) => {
            const languages = tutor.languages.map((lang) => lang.name);
            
            return (
                <TutorBox
                    key={id}
                    id={tutor.id}
                    tutor={`${tutor.user.first_name} ${tutor.user.last_name}`}
                    course={tutor.course}
                    languages={languages}
                    hourlyRate={tutor.rate}
                    description={tutor.description}
                    rating={tutor.rating ? tutor.rating.rating : 0}
                    tutorInfo={tutor}
                    profilePictureURL={tutor.user.profile_picture}
                />
            );
        })
    )}, [tutors]);

    return (
        <div className="flex-column">
            <PageIntro 
                active="tutors"
                title="Tutors"
                description="Find tutors that fit your preference."
                background="background-image-tutors"
            />

            <div id="tutors--container">
                <TutorsSearchBar 
                    formData={formData}
                    setFormData={setFormData}
                    tutors={tutors}
                    setTutors={setTutors}
                    showFilters={showFilters}
                    setShowFilters={setShowFilters}
                />

                <div id="tutors-available--container" ref={topRef}>
                    <h1 className="large-bold-title">Available Tutors{formData.course !== "" ? ` for ${extractName(formData.course)}` : ""}</h1>
                    <hr className="small-hr" id="tutors-hr"></hr>
                    {tutorsElements && tutorsElements.length === 0 ? <p>There is no tutor available.</p> : tutorsElements}
                </div>
            </div>

            {tutors.length !== 0 && !showFilters
                && <Pagination 
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            }

            <Footer />
        </div>
    )
}