import { Link } from "react-router-dom";
import React from 'react';

export default function SignInPopUp({ tutor, tutorInfo }) {
    return (
        <div className="notification--whole-page whole-page--blurred">
            <div className="notification--container">
                <lord-icon
                    src="https://cdn.lordicon.com/ygvjgdmk.json"
                    trigger="hover"
                    colors="primary:#00ac97"
                    style={{ width: "100px", height: "100px" }}>
                </lord-icon>

                <h2>Sign In Required</h2>

                <h6>Please sign in to book your session and enjoy our services.</h6>

                <div className="sign-in-popup--buttons">
                    <Link to="/sign-in" className="sign-in--button">Sign In</Link>
                    <Link 
                        className="back--button"
                        to="/tutor/courses" 
                        state={
                            {
                                tutor,
                                tutorInfo
                            }
                        }
                    >Back</Link>
                </div>
            </div>
        </div>
    )
}