import { useState } from 'react';
import useAuthContext from './useAuthContext';
import api from '../api';

export default function useLogin() {
    const [error, setError] = useState(null);
    const { setToken } = useAuthContext();
   
    // API to resent the email
    const resendEmail = async (token) => {
        try {
            await api.post("/user/sendConfirmationEmail", 
                {}, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`
                    }
                }
            );
            setError(null);
        } catch (error) {
            setError(error.response?.data?.detail || "An error occurred.");
        }
    };

    const login = async (formData) => {
        try {
            setError(null);
            const { data } = await api.post('/login', formData);
            setToken(data.access);
        } catch (error) {
            const { data } = error.response;

            if (data.message && data.token) {
                // problem with email verification
                setError([
                    <span>Email Verification is needed. </span>,
                    <span className="underlined-bold" onClick={() => resendEmail(data.token)}>Resend Email</span>
                ])
            } else {
                setError(error.response.data);
            }
        }
    }

    return { login, error, setError };
}