import { useState } from "react";
import { Navbar } from "../Navbar";
import HomeIntro from "./HomeIntro";
import Majors from "./Majors";
import HowItWorks from "./HowItWorks";
import Benefits from "./Benefits";
import { Footer } from "../Footer";
import { Error } from "../Errors/Error";
import RevealOnScroll from "../../animations/RevealOnScroll";
import FAQ from "./FAQ";

export default function Home() {
    const [showError, setShowError] = useState(false);

    return (
        <div className="full-width-height">
            {showError && 
            <Error 
                active="home" 
            />}

            {!showError && <div className="flex-column">
                <Navbar 
                    active="home" 
                />
                <HomeIntro />
                <div id="home--container" className="page-container">
                    <Majors 
                        setShowError={setShowError}
                    />
                    <HowItWorks />
                    <RevealOnScroll once={true}>
                        <Benefits />
                    </RevealOnScroll>
                    <FAQ />
                    {/* <Reviews /> */}
                </div>
                <Footer />
            </div>}
        </div>
    )
}