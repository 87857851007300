import React, { createContext, useReducer } from "react"

export const PendingsContext = createContext();

const pendingsReducer = (state, action) => {
    switch(action.type) {
        case 'INIT_PENDINGS':
            if (!state.tutorPendings && !action.payload.isUser) {
                return {
                    ...state,
                    tutorPendings: action.payload.newPendings
                }
            }

            if (!state.userPendings && action.payload.isUser) {
                return {
                    ...state,
                    userPendings: action.payload.newPendings
                }
            }
            return state;

        case 'FORCE_PENDINGS':
            if (!action.payload.isUser) {
                return {
                    ...state,
                    tutorPendings: action.payload.newPendings
                }
            } else {
                return {
                    ...state,
                    userPendings: action.payload.newPendings
                }
            }

        case 'DELETE_PENDING':
            const { isTutor, bookingId } = action.payload;
            const currArr = isTutor ? state.tutorPendings : state.userPendings;
            const filteredArr = currArr.filter(pending => pending.booking.id !== bookingId);
            
            if (isTutor) {
                return {
                    ...state,
                    tutorPendings: filteredArr
                }
            } else {
                return {
                    ...state,
                    userPendings: filteredArr
                }
            }

        case 'RESET_PENDINGS':
            return {
                tutorPendings: null,
                userPendings: null
            };

        default:
            return state;
    }
} 

export const PendingsContextProvider = ({ children }) => {
    const [pendings, dispatch] = useReducer(pendingsReducer, {
        tutorPendings: null,
        userPendings: null
    });

    return (
        <PendingsContext.Provider value={{ ...pendings, dispatch }}>
            {children}
        </PendingsContext.Provider>
    )
}