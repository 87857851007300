import { useContext } from 'react';
import { BookingsContext } from '../context/BookingsContext';

export default function useBookingsContext() {
    const context = useContext(BookingsContext);

    if (!context) {
        throw Error('useBookingContext must be used inside a BookingsContextProvider');
    }

    return context;
}