import React, { createContext, useReducer } from "react"

const initialState = {
    isError: false,
    errorTitle: null,
    errorMessage: null
};

const internetTitle = "No Internet Connection";
const internetMessage = "You're offline. Please check your internet connection and try again.";
export const ErrorContext = createContext();

const errorReducer = (state, action) => {
    switch(action.type) {
        case 'ERROR':
            const { isNetworkError } = action;
            const errorTitle = isNetworkError ? internetTitle : "Something Went Wrong";
            const errorMessage = isNetworkError ? internetMessage : "We've encountered a problem. Try reloading the page or go back to the home page.";

            return {
                isError: true,
                errorTitle,
                errorMessage
            };

        case 'NO_ERROR':
            return initialState;

        case 'NETWORK_ERROR':
            return {
                isError: true,
                errorTitle: internetTitle,
                errorMessage: internetMessage
            }

        default:
            return state;
    }
} 

export const ErrorContextProvider = ({ children }) => {
    const [errorDetails, dispatch] = useReducer(errorReducer, initialState);

    return (
        <ErrorContext.Provider value={{ ...errorDetails, dispatch }}>
            {children}
        </ErrorContext.Provider>
    )
}