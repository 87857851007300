import { useState, useEffect, memo, useRef } from "react";
import CoursesOptions from "../Courses/CoursesOptions";
import "../../styles/sign-in-up.css";
import "../../styles/settings.css";
import useDisableNumberInputScroll from "../../hooks/useDisableNumberInputScroll";
import api from "../../api.js";
import useLanguagesContext from "../../hooks/useLanguagesContext";
import useErrorContext from "../../hooks/useErrorContext.js";
import useLoaderContext from "../../hooks/useLoaderContext.js";

export const TutorOptions = memo((
    { handleChange, formData, setFormData , isSettings, error, coursesSelected:coursesSelectedProps, handleAdditionalInfo, handleTutoringInfo, areEmptyFieldsValue }
) => {

    useDisableNumberInputScroll();

    const [languagesOptions, setLanguageOptions] = useState([]);
    const [coursesSelected, setCoursesSelected] = useState([]);

    const languagesRef = useRef(null);
    const rateRef = useRef(null);

    const { languages, dispatch:languagesDispatch } = useLanguagesContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();

    useEffect(() => {
        // To avoid inifite rerendering
        if (JSON.stringify(coursesSelected) !== JSON.stringify(coursesSelectedProps)) {
            setCoursesSelected(coursesSelectedProps|| []);
        }
    }, [coursesSelectedProps, coursesSelected])

    useEffect(() => {
        // Get all languages
        const fetchLanguages = async () => {
            try {
                const { data } = await api.get('/getLanguages/');
                languagesDispatch({ type: 'SET_LANGUAGES', payload: data });
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        if (!languages) fetchLanguages();
    }, [languages, loadingDispatch, errorDispatch, languagesDispatch]);
    
    // Set up the language options
    useEffect(() => {
        // To deal with the language
        function handleCheckbox(event) {
            const currCourse = event.target.value;
    
            if (!event.target.checked) {
                setFormData((prev) => {
                    const currArr = prev.languages.filter(course => Number(course) !== Number(currCourse));
                    return {
                        ...prev,
                        "languages": currArr
                    }
                });
            } else {
                setFormData((prev) => {
                    const currArr = [...prev.languages];
                    currArr.push(Number(currCourse));
                    return {
                        ...prev,
                        "languages": currArr
                    };
                })
            }
        }

        if (languages) {
            setLanguageOptions(() => {
                return languages.map((lang, idx) => {
                    return <div key={idx}>
                                <input type="checkbox" id={lang.name} value={lang.id} onChange={handleCheckbox} checked={formData.languages.includes(Number(lang.id))} />
                                <label htmlFor={lang.name}>{lang.name}</label>
                            </div>;
                })
            })
        }
    }, [languages, formData, setFormData]);

    // Scroll to where the errors are
    useEffect(() => {
        if (error) {
            if (error.languages) languagesRef.current.scrollIntoView({ behavior: 'smooth' });
            else if (error.rate) rateRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [error]);
    
    return (
        <div>
            <div>
                <div className="settings-title-save">
                    <h1 className="settings-subtitle">Additional Info</h1>
                    {isSettings && <button className="save-button" onClick={() => handleAdditionalInfo()}>Save</button>}
                </div>
                <div className="form-row">
                    <label htmlFor="description" className="label">
                        <span>Give us a brief description about yourself:</span>
                    </label>
                    <textarea 
                        id="description" 
                        name="description" 
                        placeholder="Enter a description" 
                        className={`sign-up--elements${error && error.description ? " error-borders" : ""}`} 
                        onChange={handleChange} 
                        value={formData.description} 
                    />
                </div>

                <div className={`${error && error.languages ? "error-div" : ""}`} ref={languagesRef}>
                    <label className="label">
                        <span>Languages:</span>
                        <span className="required-asterix">*</span>
                    </label>
                    <div id="languages-options--signup">
                        {languagesOptions}
                    </div>
                </div>
                {isSettings && error && error.languages && <p className="error-p">{error.languages}</p>}
                <hr></hr>

                <div className="settings-title-save">
                    <h1 className="settings-subtitle">Tutoring Info</h1>
                    {isSettings && <button className="save-button" onClick={() => handleTutoringInfo()}>Save</button>}
                </div>
                <div className="form-row" ref={rateRef}>
                    <label htmlFor="rate" className="label">
                        <span>Hourly rate:</span>
                        <span className="required-asterix">*</span>
                    </label>
                    <input 
                        type="number" 
                        id="rate" 
                        name="rate" 
                        placeholder="Enter your hourly rate" 
                        className={`sign-up--elements${error && error.rate ? " error-borders" : ""}`} 
                        onChange={handleChange} 
                        value={formData.rate}
                    />
                    {!areEmptyFieldsValue && error && error.rate && <p className="error-p">{error.rate}</p>}
                </div>

                <CoursesOptions 
                    formData={formData}
                    setFormData={setFormData}
                    isSettings={isSettings}
                    coursesSelected={coursesSelected}
                />
            </div>
        </div>
    )
})