import PackageContainer from "./PackageContainer";
import packages from "../../data/packages";
import { useEffect, useState } from "react";
import BackArrow from "../BackArrow";
import { useLocation } from "react-router-dom";

export default function SubscriptionPlans() {
    const location = useLocation();
    const prevRoute = location.state?.currentRoute ?? "/";

    const [packagesElement, setPackagesElement] = useState([]);

    useEffect(() => {
        setPackagesElement(packages.map((plan, idx) => {
            return (
                <PackageContainer 
                    key={idx}
                    subscriptionTitle={plan.subscriptionTitle}
                    subscriptionPrice={plan.subscriptionPrice}
                    subscriptionDuration={plan.subscriptionDuration}
                    subscriptionDurationText={plan.subscriptionDurationText}
                    subscriptionDescription={plan.subscriptionDescription}
                    prevRoute={prevRoute}
                />
            )
        }))
    }, [prevRoute])

    return (
        <div className="subscription-plans--page">
            <BackArrow 
                to={prevRoute}
                additionalClass="absolute"
            />
            <div className="subscription-plans--container">
                <h1>Tutor Subscription Packages</h1>
                    <div className="packages--container">
                        {packagesElement}
                    </div>
            </div>

        </div>
    )
}