// Compare two dates (without looking at the hours, minutes, and seconds)
function areDatesEqual(date1, date2) {
    return date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
                date1.getYear() === date2.getYear()
}

const dateHelpers = {
    areDatesEqual
}

export default dateHelpers;