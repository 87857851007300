import { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import useErrorContext from "../../hooks/useErrorContext";

export const Error = memo(() => {
    const { errorTitle, errorMessage, dispatch:errorDispatch } = useErrorContext();
    const navigate = useNavigate();

    function disableError() {
        errorDispatch({ type: 'NO_ERROR' });
    }

    const reload = () => {
        navigate(0);
    }

    return (
        <div id="error--container">
            <div className="error-title--container">
                <h2>{errorTitle || "An Error Occurred"}</h2>
                <span className="material-symbols-outlined error-icon">feedback</span>
            </div>
            
            <hr></hr>

            <div className="error-description--container">
                <p>{errorMessage || "An error occured. Please try again."}</p>
                <div className="error-buttons--container">
                    <Link to="/" className="small-button" onClick={disableError}>Back to Home</Link>
                    <Link onClick={reload} className="try-again--error">Try again</Link>
                </div>
            </div>
        </div>
    )
})