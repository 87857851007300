import { useState, useEffect } from 'react';
import { TutorOptions } from '../Tutors/TutorOptions';
import BackArrow from '../BackArrow';
import useOverflowContext from '../../hooks/useOverflowContext';
import { EditHours } from './EditHours';
import validators from '../../helpers/validators';
import useUserInfoContext from '../../hooks/useUserInfoContext';
import useLoaderContext from '../../hooks/useLoaderContext';
import BannedUsers from './BannedUsers';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastHelpers from "../../helpers/toastHelper";
import { useLocation } from 'react-router-dom';
import apiPrivate from '../../apiPrivate.js';

export default function TutorSettings() {
    const { toastError, toastSuccess } = toastHelpers;
    const location = useLocation();
    const { errorMessage, successMessage, currentRoute:prevRoute } = location.state;

    const [formData, setFormData] = useState({
        description: "",
        rate: "",
        languages: [],
        taughtCourses: []
    })

    const [originalForm, setOriginalForm] = useState(null);
    
    const { user:userInfo, dispatch:userInfoDispatch } = useUserInfoContext();
    const { dispatch:loadingDispatch } = useLoaderContext();
    const [taughtCourses, setTaughtCourses] = useState([]);

    const [error, setError] = useState(null);
    const { isValidRate } = validators;

    const { dispatch:overflowDispatch } = useOverflowContext();
    
    
    // Get the updated userInfo (languages and courses)
    const getUserInfo = async () => {
        try {
            loadingDispatch({ type: 'LOAD' });
            const { data } = await apiPrivate.get('/user/getInfo');
            
            userInfoDispatch({ type: 'FORCE_USERINFO', payload: data });
            loadingDispatch({ type: 'NO_LOAD' });
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    useEffect(() => {
        if (userInfo) {
            setOriginalForm({
                description: userInfo.description,
                languages: userInfo.languages,
                rate: userInfo.rate,
                taught_courses: userInfo.taught_courses
            })
        }
    }, [userInfo])

    // Change description
    const changeDescription = async () => {
        try {
            await apiPrivate.put("/tutor/changeDescription", {
                description: formData.description
            })

            userInfoDispatch({ type: 'EDIT_ADVANCED_USERINFO', payload: {
                description: formData.description
            }});
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

     // Change languages
     const changeLanguages = async () => {
        try {
            await apiPrivate.put("/tutor/changeLanguages", {
                languages: formData.languages
            })

            getUserInfo();
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

     // Change rate
     const changeRate = async () => {
        try {
            await apiPrivate.put("/tutor/changeRate", {
                rate: formData.rate
            })

            userInfoDispatch({ type: 'EDIT_ADVANCED_USERINFO', payload: {
                rate: Number(formData.rate)
            }});
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

     // Change courses
     const changeCourses = async () => {
        try {
            await apiPrivate.put("/tutor/changeCourses", {
                courses: formData.taughtCourses
            })

            getUserInfo();
            toastSuccess(successMessage);
        } catch (error) {
            toastError(errorMessage);
        }
    }

    useEffect(() => {
        if (userInfo && Object.keys(userInfo).length !== 0) {
            const languagesSelected = [...userInfo.languages].map((lang) => lang.id);
            const coursesSelected = [...userInfo.taught_courses.map((course) => course.id)]

            setFormData(() => {
                return {
                    description: userInfo.description,
                    rate: userInfo.rate,
                    languages : languagesSelected,
                    taughtCourses: coursesSelected
                }
            })

            setTaughtCourses(userInfo.taught_courses);
        }
    }, [userInfo]);

    // Handle form change
    function handleChange(event) {
        setError(null);

        setFormData((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value
            }
        })
    }

    // Let the user scroll again
    useEffect(() => {
        overflowDispatch({ type: 'SET_FREEZE', payload: false });
    }, [overflowDispatch])

    // Function that compares arrays of objects (with objects having a unique id)
    function areSameArray(originalArr, arr) {
        const seen = new Set();

        for (const obj of originalArr) {
            seen.add(obj.id);
        }

        for (const id of arr) {
            if (!seen.has(id)) return false;
        }

        return true;
    }

    // Handle the change of description + languages
    async function handleAdditionalInfo() {
        if (formData.description !== originalForm.description) {
            await changeDescription();
        }
        
        if (!areSameArray(originalForm.languages, formData.languages)) {
            if (formData.languages.length === 0) {
                setError({ "languages": "At least one language should be chosen." });
            } else {
                await changeLanguages();
            }
        }
    }

    // Handle the change of rate + courses
    async function handleTutoringInfo() {
        const err = {};

        if (Number(formData.rate) !== Number(originalForm.rate)) {
            if (isValidRate(formData.rate, err)) await changeRate();
            setError(err);
        }

        if (!areSameArray(originalForm.taught_courses, formData.taughtCourses)) {
            await changeCourses();
        }
    }

    return (
        <div id="advanced-settings--container">
            <ToastContainer
                position="bottom-left"
                hideProgressBar={false}
                draggable
                theme="colored"
            />

            <div id="advanced-settings--title">
                <BackArrow 
                    to={prevRoute}
                />
                <h1 className="h1-title">Advanced Settings</h1>
            </div>

            <div id="advanced-settings--content">
                <TutorOptions 
                    handleChange={handleChange}
                    formData={formData}
                    setFormData={setFormData}
                    isSettings={true}
                    coursesSelected={taughtCourses}
                    handleAdditionalInfo={handleAdditionalInfo}
                    handleTutoringInfo={handleTutoringInfo}
                    error={error}
                />
                <hr></hr>
                <BannedUsers />
                <hr></hr>
                <EditHours 
                    successMessage={successMessage}
                    errorMessage={errorMessage}
                />
            </div>
        </div>
    )
}