import React, { createContext, useReducer } from "react"

export const BookingsContext = createContext();

const bookingsReducer = (state, action) => {
    switch(action.type) {
        case 'INIT_BOOKINGS':
            if (!state.tutorBookings && !action.payload.isUser) {
                return {
                    ...state,
                    tutorBookings: action.payload.newBookings
                }
            }

            if (!state.userBookings && action.payload.isUser) {
                return {
                    ...state,
                    userBookings: action.payload.newBookings
                }
            }
            return state;

        case 'FORCE_BOOKINGS':
            if (!action.payload.isUser) {
                return {
                    ...state,
                    tutorBookings: action.payload.newBookings
                }
            } else {
                return {
                    ...state,
                    userBookings: action.payload.newBookings
                }
            }

            case 'DELETE_BOOKING':
                const { day: newDay, bookingId, isTutorBookings } = action.payload;
                let newArr = isTutorBookings ? [...state.tutorBookings] : [...state.userBookings];
                const currDay = new Date(newDay);
                const currMonthIndex = newArr.findIndex(curr => curr.month === currDay.getMonth() + 1);
            
                const day = currDay.getDate();
                const daysArr = newArr[currMonthIndex].days[day] || []; // Ensure daysArr is defined
            
                // Filter out the booking with the matching ID
                const updatedDaysArr = daysArr.filter(booking => booking.id !== bookingId);
            
                // If there are no bookings left for that day, delete the day
                if (updatedDaysArr.length === 0) {
                    delete newArr[currMonthIndex].days[day];
                } else {
                    newArr[currMonthIndex].days[day] = updatedDaysArr;
                }
            
                return {
                    ...state,
                    [isTutorBookings ? 'tutorBookings' : 'userBookings']: newArr
                };
            
            

        case 'RESET_BOOKINGS':
            return {
                tutorBookings: null,
                userBookings: null
            };

        default:
            return state;
    }
} 

export const BookingsContextProvider = ({ children }) => {
    const [bookings, dispatch] = useReducer(bookingsReducer, {
        tutorBookings: null,
        userBookings: null
    });

    return (
        <BookingsContext.Provider value={{ ...bookings, dispatch }}>
            {children}
        </BookingsContext.Provider>
    )
}