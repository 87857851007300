import { useState, useEffect } from "react";
import BackArrow from "../BackArrow";
import { useLocation, useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import { ToastContainer, toast } from 'react-toastify';
import api from "../../api.js";
import useLoaderContext from "../../hooks/useLoaderContext.js";
import useErrorContext from "../../hooks/useErrorContext.js";

export default function VerificationCode() {
    const [error] = useState(null);
    const [code, setCode] = useState("");
    
    const navigate = useNavigate();
    
    const [canResend, setCanResend] = useState(false);
    const resendInterval = 60000; // 1 min
    const resendIntervalSeconds = resendInterval / 1000;
    const [timer, setTimer] = useState(resendIntervalSeconds);

    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer <= 1) {
                    clearInterval(countdown);
                    setCanResend(true);
                    return resendIntervalSeconds; // Reset timer
                }

                return prevTimer - 1;
            });
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(countdown);
    }, [timer, resendIntervalSeconds]);

    const toastMessage = (message) => {
        toast.error(message, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "toast-error",
            progressClassName: "toast-error-progress"
        });
    }
    
    const location = useLocation();
    const { email } = location ? location.state : "";

    // Submit the OTP
    const verifyOTP = async (otp) => {
        try {
            await api.post("/user/verifyOtp", {
                email,
                otp,
            });

            // the OTP request was successful
            navigate("/new-password", { state: { email } });
        } catch (error) {
            setCode("");
            toastMessage(error.response.data)
        }
    };
    
    // Resend the OTP 
    const forgotPassword = async () => {
        try {
            await api.post("/user/forgotPassword", {
                email,
            });
            
            // the OTP request was successful
            setCanResend(false);
        } catch (error) {
            loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
        }
    };
    
    // Handle form submit
    async function handleSubmit(submittedCode) {
        await verifyOTP(submittedCode);
    }

    // Handle link resend
    async function handleResend() {
        await forgotPassword(email);
        setCanResend(false);
        setTimer(resendIntervalSeconds);
    }

    return (
        <div id="sign-up--container">
            <BackArrow 
                to="/forgot-password"
                additionalClass="absolute"
            />

            <ToastContainer
                position="bottom-left"
                hideProgressBar={false}
                draggable
                theme="colored"
            />
            <div className='sign-in-up--background' style={{ animation: "none" }}>
                <div className="contact-section" style={{ animation: "none", opacity: "1" }}>
                    <div className="contact-header">
                        <h2>Verification Code</h2>
                    </div>

                    <h6 className="forgot-password-p">A verification code has been sent to the previously provided email. Please enter it below to complete your reset password request.</h6>
                    <div className="form-container">
                        <div className="verification-code--container">
                            <VerificationInput 
                                classNames={{
                                    container: "verification-input--container",
                                    character: "verification-input--character",
                                    characterInactive: "verification-input-char--inactive",
                                    characterSelected: "verification-input-char--selected",
                                    characterFilled: "verification-input--char-filled",
                                  }}
                                  placeholder="_"
                                  validChars="0-9"
                                  inputProps={{ inputMode: "numeric", autoComplete: "one-time-code" }}
                                  onComplete={handleSubmit}
                                  onChange={(codeInputted) => setCode(codeInputted)}
                                  value={code}
                            />
                            <div id="sign-in-up-switch">
                                <p>Didn't receive a code?</p>
                                {canResend ? 
                                <button 
                                    onClick={handleResend}
                                    className="resend-otp--button"
                                >Resend</button>
                                :
                                <p>
                                    <span>Resend in </span>
                                    <span className="resend-otp--button">{timer}</span>
                                    <span> seconds</span>
                                </p>
                                }
                            </div>
                        </div>
                        {error && <p className="error-p" style={{ marginTop: "-10px" }}>Incorrect code</p>}
                    </div>
                </div>
            </div>
        </div>
    );
}
