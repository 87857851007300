import React, { memo } from "react"
import BackArrow from "./BackArrow"

export const  TitleBack = memo(({ title, to = "/", state = {}, onClick = () => {} }) => {
    return (
        <div id="title-back" className="title-back">
            <BackArrow 
                to={to}
                state={state}
                onClick={onClick}
            />

            <div className="title-back--full-title" >
                <h1 className="h1-title">{title}</h1>
            </div>
        </div>
    )
})