import { useContext } from 'react';
import { LanguagesContext } from '../context/LanguagesContext';

export default function useLanguagesContext() {
    const context = useContext(LanguagesContext);

    if (!context) {
        throw Error('useBookingContext must be used inside a LanguagesContextProvider');
    }

    return context;
}