import React, { createContext, useReducer, useEffect } from "react"

export const OverflowContext = createContext();

const overflowReducer = (state, action) => {
    switch(action.type) {
        case "SET_FREEZE":
            return action.payload;

        default:
            return state;
    }
} 

export const OverflowContextProvider = ({ children }) => {
    const [freezeScreen, dispatch] = useReducer(overflowReducer, false);

    useEffect(() => {
        if (freezeScreen) document.body.classList.add('no-overflow');
        else document.body.classList.remove('no-overflow');
    }, [freezeScreen])

    return (
        <OverflowContext.Provider value={{ freezeScreen, dispatch }}>
            {children}
        </OverflowContext.Provider>
    )
}