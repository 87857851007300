import apiPrivate from "../apiPrivate";

// Get the tutors pendings
const getTutorPendings = async (pendingsDispatch, loadingDispatch, errorDispatch, setIsFetched) => {
    try {
        loadingDispatch({ type: 'LOAD' });
        const { data } = await apiPrivate.get("/tutor/getPendingRequests");
       
        pendingsDispatch({ type: 'INIT_PENDINGS', payload: {
            isUser: false,
            newPendings: data.detail ? [] : data
        }});

        setIsFetched(prev => {
            return {
                ...prev,
                tutorPendings: true
            }
        })
    } catch (error) {
        loadingDispatch({ type: 'NO_LOAD' });
        errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
    }
}

// Get the user pendings
const getUserPendings = async (pendingsDispatch, loadingDispatch, errorDispatch, setIsFetched) => {
    try {
        loadingDispatch({ type: 'LOAD' });
        const { data } = await apiPrivate.get("/user/getPendingRequests");
        
        pendingsDispatch({ type: 'INIT_PENDINGS', payload: {
            isUser: true,
            newPendings: data.detail ? [] : data
        }});

        setIsFetched(prev => {
            return {
                ...prev,
                userPendings: true
            }
        })
    } catch (error) {
        loadingDispatch({ type: 'NO_LOAD' });
        errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
    }
}

const pendingCalls = {
    getUserPendings, 
    getTutorPendings
}

export default pendingCalls;