import apiPrivate from "../apiPrivate";

// Get the user info to find if tutor or not
const getUserInfo = async (userInfoDispatch, loadingDispatch, errorDispatch) => {
    try {
        loadingDispatch({ type: 'LOAD' });
        const { data } = await apiPrivate.get('/user/getInfo');
        userInfoDispatch({ type: 'INIT_USERINFO', payload: data });
    } catch (error) {
        loadingDispatch({ type: 'NO_LOAD' });
        errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
    }
}

// Get the subscription status of tutors
const getActivationStatus = async (subscriptionDispatch, loadingDispatch, errorDispatch) => {
    try {
        loadingDispatch({ type: 'LOAD' });
        const { data } = await apiPrivate.get("tutor/getActivationStatus");
        subscriptionDispatch({ type: 'SET_STATUS', payload: data });
    } catch (error) {
        loadingDispatch({ type: 'NO_LOAD' });
        errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
    }
}

// Get the subscription remaining days of the tutor
const getRemainingDays = async (subscriptionDispatch, errorDispatch, loadingDispatch, setIsFetched) => {
    try {
        loadingDispatch({ type: 'LOAD' });
        const { data } = await apiPrivate.get("tutor/getDaysLeft");
        subscriptionDispatch({ type: 'SET_REMAINING_DAYS', payload: data });
        
        setIsFetched(prev => {
            return {
                ...prev,
                remainingDays: true
            }
        })
    } catch (error) {
        loadingDispatch({ type: 'NO_LOAD' });
        errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
    }
}

const userCalls = {
    getUserInfo,
    getActivationStatus,
    getRemainingDays
}

export default userCalls;