import { useState, useEffect } from 'react';
import useErrorContext from '../hooks/useErrorContext';

export default function NetworkStatus() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { dispatch:errorDispatch } = useErrorContext();

  // Check the internet connection
  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  // Throw an error if no internet connection
  useEffect(() => {
    if (!isOnline) errorDispatch({ type: 'NETWORK_ERROR' })
  }, [isOnline, errorDispatch]);

  return;
};