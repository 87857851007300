import useAuthContext from "../../hooks/useAuthContext";
import { useState, useEffect } from "react";
import BannedUserBox from "./BannedUserBox";
import useLoaderContext from "../../hooks/useLoaderContext.js";
import useErrorContext from "../../hooks/useErrorContext.js";
import apiPrivate from "../../apiPrivate.js";

export default function BannedUsers() {
    const { token } = useAuthContext();
    const { dispatch:errorDispatch } = useErrorContext();
    const { dispatch:loadingDispatch } = useLoaderContext();

    const [bannedUsers, setBannedUsers] = useState([]);
    const [bannedUsersElement, setBannedUsersElement] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState(null);

    // Get banned users
    useEffect(() => {
        const getBannedUsers = async () => {
            try {
                const { data } = await apiPrivate.get("/tutor/getBannedUsers")
                setBannedUsers(data);
            } catch (error) {
                loadingDispatch({ type: 'NO_LOAD' });
                errorDispatch({ type: 'ERROR', isNetworkError: !error.response });
                
            }
        }

        if (token) getBannedUsers();
    }, [token, loadingDispatch, errorDispatch]);

    // Construct the banned users element
    useEffect(() => {
        setBannedUsersElement(bannedUsers.map((user, idx) => {
            return (
                <BannedUserBox 
                    user={user}
                    key={idx}
                    idx={idx}
                    activeDropdown={activeDropdown}
                    setActiveDropdown={setActiveDropdown}
                    setBannedUsers={setBannedUsers}
                />
            )
        }))
    }, [bannedUsers, activeDropdown])

    return (
        <div>
            <label className="label">
                <h1 className="settings-subtitle margin-bottom-20">Blocked Users</h1>
            </label>
            {bannedUsers.length === 0 ?
            <p className="banned-users-p">You haven't blocked any user.</p>
            : 
            <div className="banned-users--container">
                {bannedUsersElement}
            </div>}
        </div>
    )
}