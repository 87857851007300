import React from "react";
import "../../styles/styles.css";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import RevealOnScroll from "../../animations/RevealOnScroll";

export default function TutorBox(props) {
    const halfStar = "half", fullStar = "full", emptyStar = "empty";

    // I have to do this so I don't have duplicates of languages (use props.languages)
    const { rating, tutor, tutorInfo, description, hourlyRate, profilePictureURL } = props;

    const [languages, setLanguages] = React.useState("");
    const [stars, setStars] = React.useState([]);
    const [starsDisplay, setStarsDisplay] = React.useState([]);
    
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const desktop = (
        <RevealOnScroll extraClass="tutor-box--container" once={true}>
            <div className="tutor-box--course">
                <div className="tutor-box--profile">
                    {profilePictureURL ?
                        <img 
                            src={profilePictureURL}
                            alt="Tutor Profile"
                            className="profile-picture profile-picture-img--tutors"
                            style={{ width: "132px", height: "132px" }}
                            loading="lazy"
                        />
                        :
                        <span className="material-symbols-outlined">account_circle</span>
                    }    
                </div>
                <div id="tutor-rating--container" className={rating === 0 ? "" : "rating-shown"} style={{ marginRight: `${rating === 0 ? "0" : "15px"}` }}>
                    {rating === 0 ? <p>No rating available</p> : <p>{Number(rating).toFixed(2)}</p>}
                    {rating !== 0 && <div>
                        <div>
                            {starsDisplay}
                        </div>
                    </div>}
                </div>
                <Link 
                    className="small-button" 
                    to="/tutor/courses" 
                    state={{ 
                        tutorInfo: tutorInfo,
                        tutor: tutor
                    }}
                >Book Session</Link>
            </div>

            <div className="tutor-box--tutor">
                <h3 className="light-title larger-size">{tutor}</h3>
                
                <div className="tutor-box--field">
                    <p className="very-small-title">DESCRIPTION:</p>
                    <p className="light-grey regular">{description}</p>
                </div>

                <div className="tutor-box--field">
                    <p className="very-small-title">SPEAKS:</p>
                    <p className="light-grey regular">{languages}</p>
                </div>

                <div className="tutor-box--field">
                    <p className="very-small-title">HOURLY RATE FROM:</p>
                    <div className="flex-row light-grey">
                        <p className="regular">USD&nbsp;</p>
                        <p className="bold">{hourlyRate}</p>
                    </div>
                </div>
            </div>
        </RevealOnScroll>
    );

    const mobile = (
        <RevealOnScroll extraClass="tutor-box--container" once={true}>
            <Link 
                to="/tutor/courses" 
                className="tutor-box--container"
                state={{ 
                    tutorInfo: tutorInfo,
                    tutor: tutor
                }}
            >
                <div className="tutor-box--course">
                    <div className="tutor-box--profile">
                        {profilePictureURL ?
                            <img 
                                src={profilePictureURL}
                                alt="Tutor Profile"
                                className="profile-picture profile-picture-img--tutors"
                                style={{ width: "132px", height: "132px" }}
                                loading="lazy"
                            />
                            :
                            <span className="material-symbols-outlined">account_circle</span>
                        }   
                    </div>
                </div>

                <div className="tutor-box--tutor">
                    <h3 className="light-title larger-size">{tutor}</h3>
                    
                    <div id="language--container">
                        <p className="very-small-title">SPEAKS:</p>
                        <p className="light-grey regular">{languages}</p>
                    </div>

                    <div id="hourly-rate--container">
                        <p className="very-small-title">HOURLY RATE FROM:</p>
                        <div className="flex-row light-grey">
                            <p className="regular">USD&nbsp;</p>
                            <p className="bold">{hourlyRate}</p>
                        </div>
                    </div>

                    <div id="tutor-rating--container" className={rating === 0 ? "" : "rating-shown"}>
                        {rating === 0 ? <p>No rating available</p> : <p>{Number(rating).toFixed(2)}</p>}
                        {rating !== 0 && <div>
                            <div>
                                {starsDisplay}
                            </div>
                        </div>}
                    </div>
                </div>
            </Link>
        </RevealOnScroll>
    );


    React.useEffect(() => {
        setLanguages(() => props.languages.reduce((languagesString, currLanguage) => languagesString += ", " + currLanguage));

        // Depending on how the API will go, you can think of moving it outside or not
        let { rating } = props;
        rating = Number(rating);
        let starsArr = [emptyStar, emptyStar, emptyStar, emptyStar, emptyStar];

        for (let i = 0; i < 5 && rating > 0; i++) {
            if (rating < 1) {
                starsArr[i] = halfStar;
            } else {
                starsArr[i] = fullStar;
            }

            rating -= 1;
        }

        setStars(starsArr); // specifies which stars are filled which are not
    }, [props]);


    // Creates the review stars for each tutor
    React.useEffect(() => {
        setStarsDisplay(() => {
            return stars.map((starState, key) => {
                const starName = starState === halfStar ? "star_half" : "star";
                let starClass = "material-symbols-outlined";
                starClass += starState === fullStar ? " filled-star" : "";

                return <span key={key} className={starClass}>{starName}</span>;
            })
        })
    }, [stars]);
  
    return (
        isMobile ? mobile : desktop
    )
}