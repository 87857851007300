import { useState, useEffect, useCallback } from "react";
import CourseOption from "./CourseOption";
import { SmallSearch } from "../Tutors/SmallSearch";

export default function CoursesOptions({ setFormData, isSettings, coursesSelected }) {
    const [courses, setCourses] = useState([]);
    const [coursesOptions, setCoursesOptions] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState(new Set());
    const [coursesIds, setCoursesIds] = useState(new Set());
    const [coursesNames, setCoursesNames] = useState(new Set());
    const [selectedCoursesElements, setSelectedCoursesElements] = useState([]);
    const [coursesProps, setCoursesProps] = useState([]);

    useEffect(() => {
        setCoursesProps(coursesSelected || []);
    }, [coursesSelected]);

    useEffect(() => {
        setSelectedCourses(new Set(coursesProps.map((course) => `${course.id}-${course.name}`)));
        setCoursesIds(new Set(coursesProps.map((course) => course.id)));
        setCoursesNames(new Set(coursesProps.map((course) => course.name)));
    }, [coursesProps]);

    // To deal with the checkboxes
    const handleCheckbox = useCallback((event) => {
        const { value } = event.target;
        const currId = Number(extractId(value));
        const currName = extractName(value);
        
        setSelectedCourses((prev) => {
            const newSelectedCourses = new Set(prev);

            if (prev.has(value)) {
                newSelectedCourses.delete(value);

                setCoursesIds((prevIds) => {
                    const newIds = new Set(prevIds);
                    newIds.delete(Number(currId));
                    return newIds;
                })

                setCoursesNames((prevNames) => {
                    const newNames = new Set(prevNames);
                    newNames.delete(currName);
                    return newNames;
                })
            }
            else {
                newSelectedCourses.add(value);

                setCoursesIds((prevIds) => {
                    const newIds = new Set(prevIds);
                    newIds.add(Number(currId));
                    return newIds;
                })

                setCoursesNames((prevNames) => {
                    const newNames = new Set(prevNames);
                    newNames.add(currName);
                    return newNames;
                })
            };

            return newSelectedCourses;
        })
    }, [])

    // Set up the courses options
    useEffect(() => {
        setCoursesOptions(() => {
            return courses.map((course, idx) => {
                const isChecked = coursesIds.has(Number(course.id));
                
                return (
                    <div key={idx}>
                        <input id={`courses-checkbox-${idx}`} type="checkbox" value={`${course.id}-${course.name}`} onChange={handleCheckbox} checked={isChecked} />
                        <label htmlFor={`courses-checkbox-${idx}`}>{course.name}</label>
                    </div>
                )
            }) 
        })
    }, [courses, coursesIds, handleCheckbox])

    useEffect(() => {
        setFormData(prev => {
            return {
                ...prev,
                "taughtCourses": Array.from(coursesIds)
            }
        })
    }, [coursesIds, setFormData])
    
    // Create the selected courses
    useEffect(() => {
        setSelectedCoursesElements(() => Array.from(selectedCourses).map((course, key) => {
            return <CourseOption 
                        name={extractName(course)} 
                        key={key} 
                        handleCheckbox={handleCheckbox} 
                        courseInfo={course}
                    />
        }))
    }, [coursesNames, handleCheckbox, selectedCourses])

    // Extract id and name of the checkboxes
    const extractId = (value) => value.substring(0,value.indexOf("-"));
    const extractName = (value) => value.substring(value.indexOf("-") + 1);

    return (
        <div>
            <label htmlFor="taughtCourses" className="label">Courses:</label>
            <div id="selected-courses--container" className={isSettings ? "settings-borders settings-white-bg" : ""}>
                {selectedCoursesElements}
            </div>

            <div className={isSettings ? "settings-white-bg settings-borders" : ""}>
                <SmallSearch 
                    isSettings={isSettings}
                    setCourses={setCourses}
                    isCourses={true}
                />
                <div id="courses-options" className={isSettings ? "settings-white-bg" : ""}>
                    {coursesOptions}
                </div>
            </div>
            
        </div>
    )
}