import { useEffect, useRef, useState, createRef } from 'react';
import BookingInfoBox from './BookingInfoBox';
import { TitleBack } from '../TitleBack';
import CalendarBooking from './CalendarBooking';
import mappings from '../../data/daysMappings';
import { v4 as uuidv4 } from 'uuid';
import useUserInfoContext from '../../hooks/useUserInfoContext';
import useBookingsContext from '../../hooks/useBookingsContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AllBookings() {
    const [date, setDate] = useState(null);
    const { days:daysMappings, months } = mappings;

    const [bookingsElement, setBookingsElement] = useState([]);
    const { isTutor } = useUserInfoContext();
    const { userBookings, tutorBookings } = useBookingsContext(); 
    const [bookings, setBookings] = useState(null);
    const [isTutorBookings, setIsTutoringBookings] = useState(true); // for the tutor to switch between his students and his tutors (true if it's his sessions to teach)

    useEffect(() => {
        if (isTutor !== null) setIsTutoringBookings(isTutor);
    }, [isTutor])

    const [message, setMessage] = useState({
        success: null,
        error: null
    });
    const references = useRef({});

    useEffect(() => {
        if (isTutorBookings && tutorBookings) {
            setBookings(tutorBookings);
        } else if (!isTutorBookings && userBookings) {
            setBookings(userBookings);
        }
    }, [isTutorBookings, tutorBookings, userBookings]);

    // Construct the bookings elements
    useEffect(() => {
        if (bookings) {
            const bookingArr = [];
            
            for (let i = 0; i < bookings.length; i++) {
                // we're entering each month seperately
                const currMonth = months[bookings[i].month - 1];

                const days = bookings[i].days;
                const allDays = days ? Object.keys(days) : [];

                for (let j = 0; j < allDays.length; j++) {
                    // we're entering all the days in the previous month
                    const currDay = allDays[j];
                    const hours = days[currDay];

                    const first = new Date(hours[0].start_time); 
                    
                    // add a new reference to this day
                    first.setHours(0);
                    if (!references[first]) {
                        references[first] = createRef();
                    }
                    
                    const day = first.getDay();
                    const year = first.getFullYear();
                    // pushing the title (each single day)
                    bookingArr.push(<h3 key={uuidv4()} className="all-bookings--day" ref={references[first]}>{`${daysMappings[day]} ${currDay} ${currMonth}, ${year}`}</h3>);
                    
                    for (let k = 0; k < hours.length; k++) {
                        // we're entering all the hours in the previous day
                        const day = hours[k];
                        const user = day && (day.user_details || day.tutor_details);

                        const fullName = `${user.first_name} ${user.last_name}`;
                        const profilePictureURL = user.profile_picture;
                        
                        // pushing the booking boxes
                        bookingArr.push(<BookingInfoBox 
                                            key={uuidv4()}
                                            name={fullName}
                                            email={user.email}
                                            courseDetails={day.course_details}
                                            date={day.start_time}
                                            bookingId={day.id}
                                            phoneNumber={user.profile.phone_number}
                                            hourlyRate={day.tutor_details ? day.tutor_details.rate : null}
                                            setMessage={setMessage}
                                            message={message}
                                            isTutorBookings={isTutorBookings}
                                            profilePictureURL={profilePictureURL}
                                        />)
                        }
                }
            }

            setBookingsElement(bookingArr);
        }
    }, [bookings, daysMappings, months, message, isTutorBookings]);

    // Scroll to the correct day every time the date is changed
    useEffect(() => {
        if (date && references[date]) {
            const element = references[date].current;
            const offsetTop = element.getBoundingClientRect().top + window.scrollY;
            const offset = 110;
            window.scrollTo({ top: offsetTop - offset, behavior: 'smooth' });
        }
    }, [date])


    // For sending notifications when something is updated
    const toastError = (message) => {
        toast.error(message, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "toast-error",
            progressClassName: "toast-error-progress"
        });
    }

    const toastSuccess = (message) => {
        toast.success(message, {
            position: "bottom-left",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
            className: "toast-success",
            progressClassName: "toast-success-progress"
        });
    }

    useEffect(() => {
        if (message.success) {
            toastSuccess(message.success);
        } else {
            toastError(message.error);
        }
    }, [message])
    

    return (
        <div id="all-bookings--container">  
            <ToastContainer
                position="bottom-left"
                hideProgressBar={false}
                draggable
                theme="colored"
            />    

            <TitleBack 
                title={"Your Bookings"}
            />

            <div id="all-bookings--box">
                <CalendarBooking 
                    date={date}
                    setDate={setDate}
                    isBooking={false}
                    addDate={true}
                    isTutorBookings={isTutorBookings}
                    setIsTutoringBookings={setIsTutoringBookings}
                />

                <div id="all-bookings--content">
                    {bookingsElement}
                    {/* {!date && <h3 className="no-bookings-message">Choose a date to check your bookings.</h3>} */}
                    {bookingsElement.length === 0 && date && <h3 className="no-bookings-message">You have no bookings yet.</h3>}
                </div>
            </div>
        </div>
    )
}