import { Link } from "react-router-dom";

export default function FailPayment() {
    return (
        <div className="notification--whole-page whole-page--red-bg">
            <div className="notification--container">
                <lord-icon
                    src="https://cdn.lordicon.com/ygvjgdmk.json"
                    trigger="hover"
                    colors="primary:#d94d4d"
                    style={{ width: "100px", height: "100px" }}
                ></lord-icon>

                <h1>Payment Error</h1>
                <div className="notification-container--p">
                    There was a problem processing your payment. We're sorry about the inconvenience.
                </div>

                <div className="payment--buttons">
                    <Link className="fail-payment--button" to="/">Back Home</Link>
                    <Link className="fail-payment--button" id="try-again--button">Try Again</Link>
                </div>
            </div>
        </div>
    )
}