import { useRef } from "react";
import "../../styles/styles.css";
import "../../styles/home.css";
import { useMediaQuery } from "react-responsive";

export default function Benefits() {
    const ref = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const isMedium = useMediaQuery({ query: "(max-width: 768px)" });

    const iconsStyle = isMedium ? { width: "25px", height: "25px" } : { width: "35px", height: "35px" };

    function handleMouseEnter(reference) {
        if (reference.current) {
            reference.current.dispatchEvent(new MouseEvent('mouseenter'));
        }
    }

    function handleMouseLeave(reference) {
        if (reference.current) {
            reference.current.dispatchEvent(new MouseEvent('mouseleave'));
        }
    }

    return (
        <div id="benefits--container">
            <hr></hr>

            <div id="benefits--sections">
                <div className="benefits--section">
                    <div className="benefits--subtitle">
                        <div className="home-icon--container">
                            <lord-icon
                                src="https://cdn.lordicon.com/zyzoecaw.json"
                                trigger="morph"
                                state="morph-book"
                                colors="primary:#00ac97"
                                style={{ ...iconsStyle }}
                                ref={ref[0]}
                            ></lord-icon>
                        </div>
                        {/* <span className="material-symbols-outlined home-icon">school</span> */}
                        <h3 
                            onMouseEnter={() => handleMouseEnter(ref[0])}
                            onMouseLeave={() => handleMouseLeave(ref[0])}
                        >Personalized Learning</h3>
                    </div>
                    <p
                        onMouseEnter={() => handleMouseEnter(ref[0])}
                        onMouseLeave={() => handleMouseLeave(ref[0])}
                    >Easily filter tutors based on rating, price, language, and course to find the best fit for you.</p>
                </div>

                <div className="benefits--section">
                    <div className="benefits--subtitle">
                    <script src="https://cdn.lordicon.com/lordicon.js"></script>
                        <div className="home-icon--container">
                            <lord-icon
                                src="https://cdn.lordicon.com/abfverha.json"
                                trigger="morph"
                                state="morph-calendar "
                                colors="primary:#00ac97"
                                style={{ ...iconsStyle }}
                                ref={ref[1]}
                            ></lord-icon>
                        </div>
                        {/* <span className="material-symbols-outlined home-icon">admin_panel_settings</span> */}
                        <h3
                            onMouseEnter={() => handleMouseEnter(ref[1])}
                            onMouseLeave={() => handleMouseLeave(ref[1])}
                        >Flexible Scheduling</h3>
                    </div>
                    <p
                        onMouseEnter={() => handleMouseEnter(ref[1])}
                        onMouseLeave={() => handleMouseLeave(ref[1])}
                    >Set and book sessions at the times that best suit your schedule.</p>
                </div>

                <div className="benefits--section">
                    <div className="benefits--subtitle">
                        <div className="home-icon--container">
                            <lord-icon
                                src="https://cdn.lordicon.com/nkfxhqqr.json"
                                trigger="hover"
                                stroke="bold"
                                colors="primary:#00ac97,secondary:#00ac97"
                                style={{ ...iconsStyle }}
                                ref={ref[2]}
                            >
                            </lord-icon>
                        </div>
                        {/* <span className="material-symbols-outlined home-icon">local_library</span> */}
                        <h3
                            onMouseEnter={() => handleMouseEnter(ref[2])}
                            onMouseLeave={() => handleMouseLeave(ref[2])}
                        >No Commissions</h3>
                    </div>
                    <p
                        onMouseEnter={() => handleMouseEnter(ref[2])}
                        onMouseLeave={() => handleMouseLeave(ref[2])}
                    >Tutors keep all earnings with a monthly fee, with full student access and top visibility.</p>
                </div>

                <div className="benefits--section">
                    <div className="benefits--subtitle">
                        <div className="home-icon--container">
                            <lord-icon
                                src="https://cdn.lordicon.com/kkvxgpti.json"
                                trigger="hover"
                                colors="primary:#00ac97"
                                style={{ ...iconsStyle }}
                                ref={ref[3]}
                            ></lord-icon>
                        </div>
                        {/* <span className="material-symbols-outlined home-icon">payments</span> */}
                        <h3
                            onMouseEnter={() => handleMouseEnter(ref[3])}
                            onMouseLeave={() => handleMouseLeave(ref[3])}
                        >Increased Visibility</h3>
                    </div>
                    <p
                        onMouseEnter={() => handleMouseEnter(ref[3])}
                        onMouseLeave={() => handleMouseLeave(ref[3])}
                    >Tutors are highlighted, greatly increasing their chances of being discovered by students.</p>
                </div>
            </div>
           
            {/* <hr></hr> */}
        </div>
    )
}