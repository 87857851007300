import { useContext } from 'react';
import { PendingsContext } from '../context/PendingsContext';

export default function usePendingsContext() {
    const context = useContext(PendingsContext);

    if (!context) {
        throw Error('useBookingContext must be used inside a PendingsContextProvider');
    }

    return context;
}