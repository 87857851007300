import { useContext } from 'react';
import { MajorsContext } from '../context/MajorsContext';

export default function useMajorsContext() {
    const context = useContext(MajorsContext);

    if (!context) {
        throw Error('useBookingContext must be used inside a MajorsContextProvider');
    }

    return context;
}