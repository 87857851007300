import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useMediaQuery } from 'react-responsive';
import faqQuestions from "../../data/faqQuestions";

export default function FAQ() {
    const isMobile = useMediaQuery({ query: "(max-width: 475px)" });
    const [accordionElement, setAccordionElement] = useState([]);

    useEffect(() => {
        setAccordionElement(faqQuestions.map((question, idx) => {
            return (
                <Accordion.Item eventKey={idx} key={idx}>
                    <Accordion.Header>{question.prompt}</Accordion.Header>
                    <Accordion.Body>
                        {question.answer}
                    </Accordion.Body>
                </Accordion.Item>
            )
        }))
    }, [])

    return (
        <div className="faq--container">
            <div className="top-left-border"></div>
            <div className="faq-title--container">
                <h1 className="faq-title">{`${!isMobile ? "Got Any " : ""}Questions?`}</h1>
            </div>

            <Accordion flush>
                {accordionElement}
            </Accordion>
            </div>
    )
}