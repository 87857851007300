import { useState, useEffect } from "react"

export default function ShowMore({ activeDropdown, setActiveDropdown, idx, showMoreFunctions }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(activeDropdown === idx);
    const [optionsDropdown, setOptionsDropdown] = useState(null);

    // Set up the options dropdown
    useEffect(() => {
        setOptionsDropdown(showMoreFunctions.map((option, idx) => {
            return (
                <p 
                    className="dropdown-option" 
                    onClick={option.function}
                    key={idx}
                >{option.title}</p>
            )
        }))
    }, [showMoreFunctions])

    // Handle the more info on the banned user in mobile
    function handleMoreInfo() {
        setIsDropdownOpen(prev => !prev);
        setActiveDropdown(idx);
    }

    useEffect(() => {
        setIsDropdownOpen(activeDropdown === idx);
    }, [activeDropdown, idx]);

    return (
        <div className="show-more--container">
            <span className="material-symbols-outlined show-more-icon" onClick={handleMoreInfo}>
                more_vert
            </span>

            <div className={`${isDropdownOpen ? "visible" : "not-visible"} dropdown--container`}>
                {optionsDropdown}
            </div>
        </div>
    )
}