import { useInView } from "framer-motion";
import React, { useState, useEffect, useRef } from "react";

export default function RevealOnScroll({ children, wait = 0, parentRef, extraId = "", extraClass = "", once = false }) {
    const internalRef = useRef(null);

    const ref = parentRef || internalRef;
 
    const isVisible = useInView(ref, { threshold: 0.15, once: once });
 
    const [classes, setClasses] = useState("opacity-0");
    
    useEffect(() => {
        setTimeout(() => {
            setClasses(`transition-opacity-transform 
            ${isVisible ? "opacity-1" : "opacity-0"}`);
        }, wait);
    }, [isVisible, wait])
 
    return (
        <div ref={ref} className={`${classes}${extraClass !== "" ? " " : ""}${extraClass}`} id={extraId}>
            {children}
        </div>
    );
};