import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const FontLoader = () => {
  const location = useLocation();

    useEffect(() => {
      document.fonts.ready.then(() => {
        document.body.classList.add('font-loaded');
        document.body.classList.remove('font-not-loaded');
      })
    }, [location]);

    return null;
};

export default FontLoader;
