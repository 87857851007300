import { useRef } from "react";
import "../../styles/styles.css";
import { useMediaQuery } from "react-responsive";
import RevealOnScroll from "../../animations/RevealOnScroll";

export default function HowItWorks() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const ref = useRef(null);

    return (
        <div id="how-it-works--container">
            <RevealOnScroll extraId="how-it-works--title" once={true}>
                <h1>How it works</h1>
                {!isMobile && <hr className="small-hr"></hr>}
            </RevealOnScroll>

            <div id="how-it-works--all-info">
                {!isMobile && <div id="how-it-works--img"></div>}
                <div id="how-it-works--info" ref={ref}>
                    <RevealOnScroll parentRef={ref} once={true}>
                        <div className="how-it-works--list">
                            <h1 className="number-list">1</h1>
                            <div className="paragraph-list">
                                <h3>Choose your tutor</h3>
                                <p>Browse and select a tutor who offers the course you're looking for.</p>
                            </div>
                        </div>
                    </RevealOnScroll>

                    <RevealOnScroll parentRef={ref} wait={500} once={true}>
                        <div className="how-it-works--list">
                            <h1 className="number-list">2</h1>
                            <div className="paragraph-list">
                                <h3>Pick your time</h3>
                                <p>Choose a time that suits both the tutor's availability and your preference.</p>
                            </div>
                        </div>
                    </RevealOnScroll>

                    <RevealOnScroll parentRef={ref} wait={1000} once={true}>
                        <div className="how-it-works--list">
                            <h1 className="number-list">3</h1>
                            <div className="paragraph-list">
                                <h3>Submit your booking</h3>
                                <p>Complete the booking process, and we'll handle the rest to ensure your session is confirmed.</p>
                            </div>
                        </div>
                    </RevealOnScroll>
                </div>
            </div>
        </div>
    )
}