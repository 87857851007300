import Home from "./components/Home/Home";
import Courses from "./components/Courses/Courses";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import About from "./components/About";
import Tutors from "./components/Tutors/Tutors";
import Contact from "./components/Contact";
import SignUp from "./components/SignInUp/SignUp";
import SignInPage from "./components/SignInUp/SignIn";
import ScrollToTop from "./animations/ScrollToTop";
import Booking from "./components/Booking/Booking";
import TutorSettings from "./components/Settings/TutorSettings";
import TutorCourses from "./components/Tutors/TutorCourses";
import { Error } from "./components/Errors/Error";
import ForgotPassword from "./components/SignInUp/ForgotPassword";
import VerificationCode from "./components/SignInUp/VerificationCode";
import NewPassword from "./components/SignInUp/NewPassword";
import PendingBookings from "./components/Booking/PendingBookings";
import MailConfirmation from "./components/SignInUp/MailConfirmation";
import FontLoader from "./FontLoader";

import "./styles/styles.css";
import "./styles/footer.css";
import "./styles/settings.css";
import "./styles/tutor-courses.css";
import "./styles/tutors.css";
import "./styles/contact.css";
import "./styles/about.css";
import "./styles/booking.css";
import "./styles/navbar.css";
import "./styles/animations.css";
import "./styles/calendar.css";
import "./styles/notifications.css";
import "./styles/faq.css";
import "./styles/errors.css";
import "./styles/courses.css";
import "./styles/subscription.css";

import AllBookings from "./components/Booking/AllBookings";
import useLoaderContext from "./hooks/useLoaderContext";
import Loader from "./components/Loader";
import useAuthContext from "./hooks/useAuthContext";
import useErrorContext from "./hooks/useErrorContext";
import SuccessPayment from "./components/Subscription/SuccessPayment";
import FailPayment from "./components/Subscription/FailPayment";
import SubscriptionPlans from "./components/Subscription/SubscriptionPlans";

export default function App() {
  const { token } = useAuthContext();
  const { isLoading } = useLoaderContext();
  const { isError } = useErrorContext();

  return (
    <div style={{ width: "100vw", height: "100%" }}>
      <HashRouter>
        {isLoading && <Loader />}
        {isError && <Error />}
        <ScrollToTop />
        <FontLoader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/about" element={<About />} />
          <Route path="/tutors" element={<Tutors />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/sign-up" element={!token ? <SignUp /> : <Navigate to="/" />} />
          <Route path="/sign-in" element={!token ? <SignInPage /> : <Navigate to="/" />} />
          <Route path="/tutor/courses" element={<TutorCourses />}/>
          <Route path="/tutors/booking" element={<Booking />}/>
          <Route path="/advanced-settings" element={<TutorSettings />}/>
          <Route path="/all-bookings" element={<AllBookings />}/>
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verification-code" element={<VerificationCode />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/pending-bookings" element={<PendingBookings />} />
          <Route path="/mail-confirmation" element={<MailConfirmation />} />
          <Route path="/payment-success" element={<SuccessPayment />} />
          <Route path="/payment-fail" element={<FailPayment />} />
          <Route path="/subscription-plans" element={<SubscriptionPlans />} />
        </Routes>
      </HashRouter>
    </div>
  );
}